import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const ItemType = "CARD";

const DraggableCard = ({ lead, moveCard, columnId }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType,
    item: { lead, columnId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        marginBottom: "10px",
      }}
    >
      <Card className="shadow" style={{ padding: "10px" }}>
        <CardBody>
          <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {lead.name} {lead.lastName}
          </div>
          <div style={{ color: "green", margin: "5px 0" }}>
            <FontAwesomeIcon icon={faPhone} />
          </div>
          <div style={{ fontSize: "0.9rem", color: "gray" }}>{lead.phone}</div>
          <div style={{ fontSize: "0.9rem", color: "gray" }}>
            {lead.address}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const DroppableColumn = ({ id, title, leads, moveCard }) => {
  const [, drop] = useDrop(() => ({
    accept: ItemType,
    drop: (item) => {
      if (item.columnId !== id) {
        moveCard(item.lead, item.columnId, id);
      }
    },
  }));

  return (
    <div ref={drop} style={{ minHeight: "300px", padding: "10px" }}>
      <Card className="shadow">
        <CardHeader>
          {title} ({leads.length})
        </CardHeader>
        <CardBody>
          {leads.map((lead) => (
            <DraggableCard
              key={lead.id}
              lead={lead}
              moveCard={moveCard}
              columnId={id}
            />
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

const SalesPipeline = () => {
  const { db } = useFirebase();
  const [columns, setColumns] = useState({
    1: { id: "1", title: "Information Confirmed", leads: [] },
    2: { id: "2", title: "Visited", leads: [] },
    3: { id: "3", title: "Bid Sent", leads: [] },
    4: { id: "4", title: "Closed", leads: [] },
  });

  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.leads, (data) => {
      if (data) {
        const confirmedLeads = [];
        const visitedLeads = [];
        const bidSentLeads = [];

        Object.entries(data).forEach(([key, lead]) => {
          const leadWithId = { id: key, ...lead };

          if (lead.isInfoConfirmed && !lead.isVisited) {
            confirmedLeads.push(leadWithId);
          }
          if (lead.isVisited && !lead.isbidSent) {
            visitedLeads.push(leadWithId);
          }
          if (lead.isbidSent) {
            bidSentLeads.push(leadWithId);
          }
        });

        setColumns((prevColumns) => ({
          ...prevColumns,
          1: { ...prevColumns["1"], leads: confirmedLeads },
          2: { ...prevColumns["2"], leads: visitedLeads },
          3: { ...prevColumns["3"], leads: bidSentLeads },
        }));
      } else {
        setColumns((prevColumns) => ({
          ...prevColumns,
          1: { ...prevColumns["1"], leads: [] },
          2: { ...prevColumns["2"], leads: [] },
          3: { ...prevColumns["3"], leads: [] },
        }));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const moveCard = (lead, sourceColumnId, targetColumnId) => {
    setColumns((prevColumns) => {
      const sourceColumn = prevColumns[sourceColumnId];
      const targetColumn = prevColumns[targetColumnId];

      const updatedSourceLeads = sourceColumn.leads.filter(
        (l) => l.id !== lead.id
      );
      const updatedTargetLeads = [...targetColumn.leads, lead];

      return {
        ...prevColumns,
        [sourceColumnId]: { ...sourceColumn, leads: updatedSourceLeads },
        [targetColumnId]: { ...targetColumn, leads: updatedTargetLeads },
      };
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container className="mt-4" fluid>
        <Row>
          {Object.values(columns).map((column) => (
            <Col key={column.id} md="3">
              <DroppableColumn
                id={column.id}
                title={column.title}
                leads={column.leads}
                moveCard={moveCard}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </DndProvider>
  );
};

export default SalesPipeline;
